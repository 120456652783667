<template>
  <div>
    <main-hero
      title="Kilpailuta sijoituslaina maksutta"
      subtitle="Yhdellä hakemuksella useita lainatarjouksia."
      side-title="Kilpailuta sijoituslainasi!"
      :side-boxes="sideBoxes"
      survey-title="Asiakasarviot"
      :breadcrumb="{'Sijoituslaina': ''}"
    />
    <section class="hero has-background-rlgray">
      <div class="hero-body">
        <div class="container">
          <h2 class="title has-text-white is-size-4 has-text-centered">
            Sijoituslaina kannattaa kilpailuttaa.
          </h2>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <loan-steps />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="content">
          <h2>
            Oikein käytetty sijoituslaina tarjoaa avaimet tuottavaan tekemiseen.
          </h2>
          <p>
            Jos tavoitteenasi on tehdä tuottoa, on nyt kannattavaa ottaa lainaa sijoitukseen. Sijoituslainan avulla
            voit aloittaa sijoittamisen tai parantaa jo olemassa olevan osakesalkun hajautusta ilman, että käytät omia
            säästöjäsi. Laina sijoitukseen antaa mahdollisuuden ostaa oikeaan aikaan osakkeita ilman omien varojen
            miettimistä. Tällöin puhutaan velkavivusta, jonka riskit on hyvä tiedostaa, mutta joka harkiten käytettynä
            on huomattavasti mainettaan parempi.
          </p>
          <h3>
            Mihin sijoituslainaa käytetään?
          </h3>
          <p>
            Yleisimmin sijoituslainaa päädytään ottamaan, kun tarjolla on otollinen sijoitustilanne, mutta käteisvarat
            eivät riitä tilaisuuden hyödyntämiseen. Sijoituslainan avulla voi hankkia tuottoja tarjoavaa
            sijoitusvarallisuutta. Tällä tarkoitetaan perinteisesti rahasto-osuuksia tai osakkeita, mutta myös
            sijoitusasuntoja tai metsäpalstoja voi ostaa sijoituslainalla. Nykypäivänä myös kryptovaluuttoihin
            sijoittaminen on yleistynyt. Myös yritykset ottavat lainaa sijoituksiin, kun heillä on tarve löytää
            joustava vaihtoehto rahoitustarpeisiin. Sijoituslaina soveltuu esimerkiksi käyttöpääoman, investointien ja
            yrityskauppojen rahoitukseen.
          </p>
          <p>
            Sijoituslainaa otettaessa on hyvä muistaa myös, että sijoituksen hoitamiseen liittyvät kulut voi vähentää
            verotuksessa. Tämä koskee niin sijoitusmielessä ostetun metsäpalstan, sijoitusasunnosta aiheutuvia kuluja
            kuin sijoitusneuvonnan käytöstä aiheutuvia maksujakin. Pystyt siis vähentämään niin sijoituslainan korot
            kuin muut lainasta koituvat kulut pääomatuloverotuksessasi. Kuluiksi lasketaan osinkotulot, vuokratulot,
            myyntivoitot sekä muut pääomista tulevat kulut. Sijoituslainassa onkin 100%:n verovähennyskelpoisuus, kun
            sitä hyödynnetään sijoituksiin.
          </p>
          <h3>
            Tee sijoituslainojen vertailu nyt!
          </h3>
          <p>
            Rahalaitos.fi on auttanut sijoittajia kasvattamaan tuottoaan jo yli kymmenen vuotta. Meiltä otetaan usein
            lainaa sijoitukseen, sillä pystymme tarjoamaan kilpailukykyiset korot kilpailuttamalla lainan kymmenillä
            suomalaisilla ja pohjoismaisilla pankeilla sekä luotontarjoajilla yhdellä hakemuksella.
          </p>
          <p>
            Hakemuksen teko on maksuton eikä sido hakijaa mitenkään. Sopivimman lainatarjouksen valittuasi saat rahat
            tilillesi vielä saman päivän aikana. Kauttamme otetun sijoituslainan korko ei ole sen korkeampi kuin
            kulutusluotto- tai asuntolainakaan ja sijoituslainan korot voi vähentää verotuksessa kokonaisuudessaan.
          </p>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <faq />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <subpages />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <banks />
        </lazy-hydrate>
      </div>
    </section>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'SijoitusLaina',
  components: {
    LazyHydrate,
    banks: () => import('~/components/layout/banks'),
    faq: () => import('~/components/layout/pages/sijoituslainaFaq'),
    loanSteps: () => import('~/components/layout/loanSteps'),
    mainHero: () => import('~/components/layout/mainHero'),
    subpages: () => import('~/components/layout/subpages')
  },
  data () {
    return {
      sideBoxes: [
        {
          icon: () => import('~/assets/img/icons/banks.svg?inline'),
          title: 'Sijoituslainaa jopa',
          subtitle: '25 pankilta'
        },
        {
          icon: () => import('~/assets/img/icons/time.svg?inline'),
          title: 'Helposti ja',
          subtitle: 'nopeasti'
        },
        {
          icon: () => import( '~/assets/img/icons/money.svg?inline' ),
          title: 'Sijoituslainaa jopa',
          subtitle: '70 000 €'
        }
      ]
    }
  },
  async fetch ( { store } ) {
    await store.dispatch( 'subpage/setPages', [
      {
        title: 'Lainaa ilman takaajia',
        subtitle: 'Haaveista totta nopeasti ja helposti',
        link: '/lainaa-ilman-takaajia'
      },
      {
        title: 'Lainaa 30 000 euroa',
        subtitle: 'Suuriin ja pieniin unelmiin',
        link: '/lainaa-30000-euroa'
      },
      {
        title: 'Yhdistä lainasi',
        subtitle: 'Selkiytä talouttasi ja säästä kuluissa',
        link: '/yhdista-lainat'
      }
    ] )
  },
  head () {
    return {
      title: 'Sijoituslaina - Kilpailuta sijoituslaina | Rahalaitos',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Rahalaitoksen kautta voit saada jopa 70 000 € sijoituslainaa ilman vakuuksia.
            Täytä hakemus ilmaiseksi jo tänään!`
        }
      ],
      link: [
        { rel: 'canonical', href: 'https://www.rahalaitos.fi/sijoituslaina' }
      ]
    }
  }
}
</script>
